<template>
  <v-container class="section">
    <div class="section-title">
      <h2>Local attractions in {{userCity.name}} area</h2>
    </div>
    <div class="section-text">
      Sometimes the best treasures are the ones you walk past everyday without even realizing.
      And by that we mean, there are loads of activities right on your doorstep just begging to be explored. And what better time to go try them? So maybe take a walking tour of your old city; or book a winetasting
      for 2 with views of a nearby national park; or experiment with a local eatery you've never heard of before. We've already lined up the best options for you (you can thankus later!) - so go ahead and check them out here!
    </div>
    <div class="local-attractions local-attractions--desktop">
      <div class="local-attraction" v-for="(product, index) in products" :key="index">
        <review-rating :star-size=[8,9] v-if="product.rating" :rating="product.rating" :ratingClass="getReviewClass(product.rating)" class="rating"/>
        <div class="local-attraction__container la-hover" @click="goToUrl(product.url)">
          <div class="local-attraction__header">{{product.name}}</div>
          <div class="local-attraction__location">
            <FontAwesomeIcon :icon="['far', 'location-dot']"/>
            <span>{{product.location}}</span>
          </div>
          <div class="local-attraction__price" v-if="product.price">
            <span>from</span>
            <b>{{product.price.formatted}}</b>
          </div>
          <p class="la-hover__description">
            {{product.description | trim}}
          </p>
          <a :href="product.url"
             target="_blank"
             class="la-hover__link"
          >
            See Details
            <img src="~/assets/img/arrow-left.svg" width="19" height="18" alt="">
          </a>
        </div>

        <img class="local-attraction__bg" v-lazy="product.imgUrl" :alt="product.name"/>
        <div class="local-attraction__gradient"></div>
      </div>
      <div class="local-attraction local-attraction--select">
        <nuxt-link :to="`/attractions/${userCity.slug}`" v-if="userCity && userCity.slug">
          <div class="local-attraction__image show-all">
            <img class="show-all__bg" v-lazy="'/img/woman-brooklyn-bridge-usa.png'" alt="">
            <img class="show-all__icon" src="~/assets/img/tower-white.svg" alt="">
          </div>
          <span class="local-attraction__ltext">Show All</span>
        </nuxt-link>
      </div>
    </div>
    <div class="local-attractions local-attractions--mobile" v-if="products && products.length">
      <client-only>
        <carousel v-if="isNuxtReady" :nav="false" :items="1" :margin="10" :loop="true" :center="true">
          <div class="slide" v-for="(product, index) in products" :key="index">
            <div class="local-attraction">
              <review-rating :star-size=[8,9] v-if="product.rating" :rating="product.rating" :ratingClass="getReviewClass(product.rating)" class="rating"/>
              <div class="local-attraction__container la-hover" @click="goToUrl(product.url)">
                <div class="local-attraction__header">{{product.name}}</div>
                <div class="local-attraction__location">
                  <FontAwesomeIcon :icon="['far', 'location-dot']"/>
                  <span>{{product.location}}</span>
                </div>
                <div class="local-attraction__price" v-if="product.price">
                  <span>from</span>
                  <b>{{product.price.formatted}}</b>
                </div>
                <p class="la-hover__description">
                  {{product.description | trim}}
                </p>
                <a :href="product.url"
                   target="_blank"
                   class="la-hover__link"
                >
                  See Details
                  <img src="~/assets/img/arrow-left.svg" width="16px" height="16px" alt="See Details">
                </a>
              </div>

              <img class="local-attraction__bg" v-lazy="product.imgUrl" :alt="product.name"/>
              <div class="local-attraction__gradient"></div>
            </div>
          </div>
        </carousel>
      </client-only>
      <div class="local-attraction local-attraction--select">
        <nuxt-link :to="`/attractions/${userCity.slug}`" v-if="userCity && userCity.slug">
          <div class="local-attraction__image show-all">
            <img class="show-all__bg" v-lazy="'/img/woman-brooklyn-bridge-usa.png'" alt="">
            <img class="show-all__icon" src="~/assets/img/tower-white.svg" alt="">
          </div>
          <span class="local-attraction__ltext">Show All</span>
        </nuxt-link>
      </div>
    </div>
  </v-container>
</template>

<script>
import ReviewRating from '@/components/ReviewRating/ReviewRating'
const carousel = () => window && window !== undefined ? import('vue-owl-carousel') : null
import images from '@/mixins/images.js'
import reviewClass from '@/mixins/review-class.js'

export default {
  mixins: [images, reviewClass],
  data() {
    return {
      isAnimate: false,
      userCity: {},
      products: [],
      isNuxtReady: false
    }
  },
  filters: {
    trim: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.substring(0, 100) + '...'
    }
  },
  methods: {
    goToUrl(url) {
      let routeData = this.$router.resolve(url)
      window.open(routeData.href, '_blank')
    },
    setProducts(productsList) {
      let products = []

      productsList.map(product => {
        products.push({
          url: `/attractions/${this.userCity.slug}/${product.id}-${this.productSlug(product.name)}`,
          imgUrl: {
            src: product.cover_image_url ? this.imageUrlWithProxy(product.cover_image_url, 384, 290) : this.imageUrlWithProxy(product.images?.[0], 384, 290),
            error: '/img/no-photo.svg',
            loading: '/img/no-photo.svg'
          },
          description: product.description,
          name: product.name,
          location: product.location && product.location.city_name && product.location.country_code
            ? `${product.location.city_name}, ${product.location.country_code}`
            : '',
          price: product.price_from,
          rating: product.avg_rating ? product.avg_rating.toFixed(2) : 0
        })
      })

      this.products = products
    },

    productSlug(productName) {
      const slug = productName.replace(/ /g, '-')
        .replace(',', '')
        .replace('/', '-')
        .replace(':', '-')
        .replace('(', '')
        .replace(')', '')
        .replace('---', '-')
        .replace('--', '-')
        .replace('%', '-')
        .toLowerCase()

      return slug
    },
    async getProductByLocation() {
      await this.$axios.get('/api/product/v1/geo-places/nearest').then(res => {
        this.userCity = res.data
      }).then(() => {
        this.$axios.get('/api/product/v3/activities', { params: { place_id: this.userCity.id, size: 5, sort_by: 'rating_desc'} })
          .then( res => {
            if (!res.data.activities.length) {
              this.$axios.get('/api/product/v3/activities',{ params: { place_id: 467, size: 5, sort_by: 'rating_desc' } })
                .then( res => {
                  this.userCity.name = 'New York'
                  this.userCity.slug = 'us-ny-new-york'
                  this.setProducts(res.data.activities)
                })
            } else {
              this.setProducts(res.data.activities)
            }
          })
      }).catch(err=>{console.log(err)})
    }
  },
  async mounted() {
    if (process.browser) {
      window.onNuxtReady(app => {
        this.isNuxtReady = true
      })
    }
    await this.getProductByLocation()
  },
  components: {
    ReviewRating,
    carousel
  }
}
</script>
